<template>
    <div>
        <el-upload
            multiple
            :limit="limit"
            :disabled="disabled"
            list-type="picture-card"
            :accept="accept"
            :action="sign.host || ''"
            :data="ossData"
            :before-upload="handleBeforeUpload"
            :on-change="handleChange"
            :on-preview="handleImagePreview"
            :on-remove="handleOnRemove"
            :on-exceed="handleExceed"
            :on-success="handleOnSuccess"
            :file-list="fileList"
        >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
                <template v-if="file.status !== 'success'">
                    <el-progress
                        type="circle"
                        :percentage="Number(file.percentage.toFixed(0))"
                    ></el-progress>
                </template>
                <template v-else>
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                        <!-- 预览 -->
                        <span
                            class="el-upload-list__item-preview"
                            @click="handleImagePreview(file)"
                        >
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <!-- 底部文字 -->
                        <span
                            v-show="bottomText && file.uid !== chosenFile.uid"
                            @click="handleBottomTextClick(file)"
                            class="file-bottom"
                        >
                            {{ bottomText }}
                        </span>
                        <!-- 删除 -->
                        <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleOnRemove(file, fileList)"
                        >
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                    <!-- 选中标志 -->
                    <div
                        class="chosen-file-flag"
                        v-show="bottomText && file.uid === chosenFile.uid"
                    >
                        封面
                    </div>
                </template>
            </div>
        </el-upload>
        <div class="tips">
            <template v-if="accept">
                支持扩展名：{{ accept.trim().split(',').join(' ') }}
            </template>
            <template v-if="accept && size">,</template>
            <template v-if="size"> 文件大小不能超过{{ size }}M </template>
        </div>
        <el-image
            style="height: 0; width: 0; position: absolute; z-index: -1"
            ref="preview"
            :src="currentPreviewImage"
            :preview-src-list="previewList"
        ></el-image>
    </div>
</template>

<script>
// 该组件在原LkMultipleImageUpload基础上，使用了scoped-slot，替换了默认的file插槽内容。
// 初始目的是在文件底部新增一个点击栏，点击后生成一个点击事件返回该文件
import { getOssSign } from '@/api/common';
import { replaceWhiteSpace } from '@/utils/common';

export default {
    name: 'component-multiple-image-upload',
    props: {
        accept: {
            type: String,
            default: '',
        },
        limit: {
            type: Number,
            default: 0,
        },
        // 文件大小限制，单位为 MB
        size: {
            type: Number,
        },
        tips: {
            type: String,
        },
        // 传入的list，无需补全完整路径，会自动补全
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        //  1:task 任务(默认)    2:opus  作品
        ossType: {
            type: [Number, String],
            default: '',
        },
        // 底部按钮
        bottomText: {
            type: String,
            default: '',
        },
    },
    watch: {
        list: {
            handler(value) {
                this.fileList = value.map((item) => {
                    return {
                        name: item.name,
                        url: process.env.VUE_APP_OSS_HOST + item.url,
                    };
                });
            },
            immediate: true,
        },
    },
    computed: {
        previewList() {
            return this.fileList.map((file) => file.url);
        },
    },
    data() {
        return {
            sign: {},
            ossData: {},
            fileList: [],

            /*
                阿里云上传图片不会返回图片地址。因此上传之前，会先保存图片地址，成功后再从此读取
                格式  uid: url
            */
            urlMapping: {},

            showMsg: false,

            currentPreviewImage: '', // 当前被预览的图片地址

            chosenFile: {}, //被选中的图片
        };
    },
    async mounted() {
        await this.initOssData();
    },
    methods: {
        // 初始化
        async initOssData() {
            const [response, error] = await getOssSign(this.ossType);

            if (!error) {
                this.sign = response.data.sign;

                let {
                    host,
                    policy,
                    signature,
                    expired_in,
                    accessid: OSSAccessKeyId,
                } = response.data.sign;

                // host 以 / 结尾，则去掉斜杠
                if (host.endsWith('/')) {
                    this.sign.host = host.substring(0, host.length - 1);
                }

                // 根据本机时间设置过期时间
                this.sign.expire = Date.now() + expired_in * 1000;

                this.ossData = {
                    policy,
                    signature,
                    OSSAccessKeyId,
                };
            } else {
                this.$message.error(error.msg);
            }
        },
        async handleBeforeUpload(file) {
            // 检测文件格式
            if (this.accept) {
                const accepts = this.accept.split(',');
                const isValid = accepts.find((item) => {
                    return file.name.toLocaleLowerCase().endsWith(item.toLocaleLowerCase().trim());
                });
                if (!isValid) {
                    this.$message.error('格式错误！');
                    return Promise.reject();
                }
            }

            // 检测文件大小
            if (this.size && this.size * 1024 * 1024 < file.size) {
                if (!this.showMsg) {
                    this.showMsg = true;
                    this.$message({
                        message: '尺寸超出限制',
                        type: 'error',
                        onClose: () => {
                            this.showMsg = false;
                        },
                    });
                }
                return Promise.reject();
            }

            await this.initOssData();

            // 防止文件重名
            const dir = this.sign.dir;
            const now = Date.now();
            this.ossData.key = dir + '/' + now + '-' + replaceWhiteSpace(file.name);

            // 将图片 uid 和 其路径进行保存
            this.urlMapping[file.uid] = {
                name: now + '-' + file.name,
                uid: file.uid,
                url: '/' + this.ossData.key,
            };
            return Promise.resolve();
        },
        handleChange(file, fileList) {
            this.fileList = fileList;
            this.$emit('change', fileList);
        },
        findFileByUID(uid) {
            let files = this.getFiles();
            return (
                files &&
                files.find((item) => {
                    return item.uid === uid;
                })
            );
        },
        handleOnSuccess(resp, file, fileList) {
            file = this.findFileByUID(file.uid);
            // 如果没有选中文件，则默认选中第一个上传成功的
            // 因为是异步的，所以此时file里的URL还不是网络地址
            if (!this.chosenFile.uid) {
                this.chosenFile = { uid: file.uid, url: file.url };
                this.$emit('chooseFile', this.chosenFile);
            }
            this.$emit('success', resp, file, fileList);
        },
        handleOnRemove(file, fileList) {
            // 如果被删除的是被选中的，并且上传成功的文件列表不为空
            // 如果files是false，说明有未上传成功的，那么会在onSuccess校验有没有选中文件
            if (file.uid === this.chosenFile.uid) {
                let files = this.getFiles();
                if (files) {
                    if (files.length > 1) {
                        // 因为删除文件有延时，所以需要判断一下，如果删除的第一个，则index为1
                        let index = 0;
                        if (file.uid === files[0].uid) {
                            index = 1;
                        }
                        this.chosenFile = { uid: files[index].uid, url: files[index].url };
                    } else {
                        this.chosenFile = {};
                    }
                    this.$emit('chooseFile', this.chosenFile);
                }
            }
            const index = this.fileList.findIndex((item) => item.name === file.name);
            this.fileList.splice(index, 1);
            this.$emit('remove', file, fileList);
        },
        // 格式化 fileList
        formatFileList(fileList) {
            const result = [];
            for (let i = 0; i < fileList.length; i++) {
                const item = fileList[i];
                if (item.raw) {
                    if (item.status !== 'success') {
                        // this.$message.warning('请等待文件上传完成');
                        return false;
                    }
                    result.push(this.urlMapping[item.uid]);
                } else {
                    result.push({
                        name: item.name,
                        uid: item.uid,
                        url: item.url.replace(process.env.VUE_APP_OSS_HOST, ''),
                    });
                }
            }
            return result;
        },

        // el-image 会等待图片加载车成功，才将 img 标签渲染。
        // 下方代码，等待图片load ，然后 setTimeout 等待下一轮事件循环为合理操作，无需优化
        // el-image 触发图片预览的事件绑定在 img 标签上，因此成功获取图片元素后，触发其 click 事件
        handleImagePreview(file) {
            this.currentPreviewImage = file.url;
            const image = new Image();
            image.src = this.currentPreviewImage;
            image.onload = () => {
                setTimeout(() => {
                    this.$refs.preview.$el.getElementsByTagName('img')[0].click();
                });
            };
        },
        handleExceed() {
            this.$message.warning(`最多只能上传${this.limit}个文件`);
        },
        // 获取已上传图片的列表，如果有任何异常状态，则获取失败并提示
        getFiles() {
            return this.formatFileList(this.fileList);
        },
        handleDownload() {},
        handleBottomTextClick(file) {
            file = this.findFileByUID(file.uid);
            this.chosenFile = { uid: file.uid, url: file.url };
            this.$emit('chooseFile', this.chosenFile);
        },
    },
};
</script>

<style lang="scss" scoped>
.tips {
    color: #979797;
    font-size: 14px;
}
.el-upload-list__item-thumbnail {
    width: 148px;
    height: 148px;
}
// 上传的文件底部样式
.file-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    margin: 0 !important;
    &:hover {
        color: #3c6;
    }
}
.chosen-file-flag {
    position: absolute;
    bottom: 0;
    height: 16%;
    width: 100%;
    background-color: #33cc6688;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
</style>
