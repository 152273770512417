<!--  -->
<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        :width="width"
        :title="title"
        :before-close="handleBeforeClose"
        :destroy-on-close="destroyOnClose"
        :close-on-click-modal="closeOnClickModal"
    >
        <!-- 弹框内容 -->
        <slot name="default"></slot>
        <!-- 提交按钮 -->
        <template v-slot:footer>
            <el-button type="success" :loading="uploading" @click="handleConfirm">
                {{ confirmButtonText }}
            </el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'LkDialog',
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '50%',
        },
        title: {
            type: String,
        },
        confirmButtonText: {
            type: String,
            default: '提交',
        },
        // 关闭后是否销毁实例
        destroyOnClose: {
            type: Boolean,
            default: false,
        },
        // 点击遮罩是否可以关闭弹框
        closeOnClickModal: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            uploading: false,
        };
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            },
        },
    },
    watch: {},
    methods: {
        // 处理提交
        handleConfirm() {
            this.uploading = true;
            this.$emit('confirm', this.done, this.cancel);
        },
        // 当弹框准备关闭
        handleBeforeClose(done) {
            if (this.uploading) {
                this.$message({
                    type: 'warning',
                    message: '数据上传中，请稍等',
                    duration: 1000,
                });
                return;
            }
            done();
        },
        done() {
            // 结束提交状态并关闭弹框
            this.uploading = false;
            this.dialogVisible = false;
        },
        cancel(msg) {
            if (msg) {
                this.$message({
                    type: 'error',
                    message: msg,
                });
            }
            this.uploading = false;
        },
    },
};
</script>
<style lang="scss" scoped></style>
