/**
 * @description 公共接口
 */
import request from '@/utils/request';

/**
 * 获取 OSS 签名
 * @type // 1:task 任务(默认)    2:opus  作品
 */
export function getOssSign(type = '1') {
    return request({
        url: '/v1/common/oss',
        method: 'get',
        params: {
            type,
        },
    });
}

/**
 * 合同签署完成之后，触发完成合同签署事件
 * @param data
 * @returns
 */
export function webhookContract(data = []) {
    return request({
        url: '/v1/common/callback/contract',
        method: 'post',
        data: data,
    });
}

export function webhookUserSign(data = []) {
    return request({
        url: '/v1/common/callback/auth',
        method: 'post',
        data: data,
    });
}
