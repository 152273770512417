<template>
    <div class="upload">
        <!-- 上传作品详情页 -->
        <el-form
            ref="uploadForm"
            :model="uploadForm"
            :rules="formRules"
            size="small"
            label-position="left"
            label-width="6em"
        >
            <!-- 上传图片 -->
            <el-form-item required label="添加作品" prop="opus_images">
                <lk-multiple-image-upload
                    ref="uploadOpus"
                    accept=".jpg, .jpeg, .png , .gif"
                    :limit="100"
                    :size="1.5"
                    :ossType="2"
                    bottomText="设置封面"
                    @chooseFile="handleChooseFile"
                    :list="opus_images"
                ></lk-multiple-image-upload>
                <el-alert
                    type="success"
                    :closable="false"
                    :title="uploadTip"
                    size="small"
                ></el-alert>
            </el-form-item>
            <!-- 标题 -->
            <el-form-item label="输入标题" required prop="name">
                <el-input
                    v-model="uploadForm.name"
                    :maxlength="30"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <!-- 分类 -->
            <el-form-item label="选择分类" required prop="sub_category_id">
                <!-- 一级分类 -->
                <el-select
                    v-model="uploadForm.category_id"
                    @change="handleFirstCategoryChange"
                    class="selector"
                >
                    <el-option
                        v-for="(item, key) in firstCategoryOptions"
                        :key="key"
                        :value="key"
                        :label="item.label"
                    ></el-option>
                </el-select>
                <!-- 二级分类 -->
                <el-select v-model="uploadForm.sub_category_id">
                    <el-option
                        v-for="(item, key) in secondCategoryOptions"
                        :key="key"
                        :value="key"
                        :label="item.label"
                    ></el-option>
                </el-select>
            </el-form-item>
            <!-- 简介 -->
            <el-form-item required prop="descinfo" label="作品简介">
                <el-input
                    v-model="uploadForm.descinfo"
                    type="textarea"
                    :maxlength="1000"
                    :autosize="{ minRows: 2 }"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <!-- 标签 -->
            <el-form-item label="标签" required prop="label_id">
                <el-tag v-for="tag in selectedTags" :key="tag.id">{{ tag.name }}</el-tag>
                <el-button type="success" size="small" @click="tagsDialogVisible = true">
                    +添加标签
                </el-button>
            </el-form-item>
            <!-- 更多设置 -->
            <el-form-item label="更多设置">
                <el-checkbox v-model="forSale">上市作品</el-checkbox>
                <el-checkbox v-model="onlySelf">仅自己可见</el-checkbox>
            </el-form-item>
            <el-form-item label="获奖作品">
                <!-- 获奖记录展开 -->
                <div>
                    <el-checkbox-group v-model="uploadForm.award">
                        <el-checkbox
                            v-for="(award, key) in awards"
                            :value="key"
                            :label="Number(key)"
                            :key="key"
                        >
                            {{ award }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <!-- 上传证书 -->
            <el-form-item label="上传证书">
                <lk-multiple-image-upload
                    ref="uploadCertificate"
                    accept=".jpg, .jpeg, .png"
                    :limit="100"
                    :size="1.5"
                    :ossType="2"
                    :list="certificate"
                ></lk-multiple-image-upload>
            </el-form-item>
        </el-form>
        <!-- 标签的详情弹框 -->
        <upload-opus-tags-dialog
            :visible.sync="tagsDialogVisible"
            :tags="tags"
            :_selectedTags="selectedTags"
            @updateSelectedTags="handleUpdateSelectedTags"
        ></upload-opus-tags-dialog>
    </div>
</template>

<script>
import { REWARD_TYPE } from '@/constant/recruit';
import LkMultipleImageUpload from './LkMultipleImageUploadExpand';

// 作品标签组件
import UploadOpusTagsDialog from './UploadOpusTagsDialog.vue';
export default {
    name: 'UploadOpusDetail',
    components: { LkMultipleImageUpload, UploadOpusTagsDialog },
    props: {
        category: {},
        // 所有标签
        tags: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        this.uploadTip =
            '小洛提示：为倡导原创，维护合法版权，请确认您上传的作品（包括但不限于产品图、品牌图、场景图、人物肖像图等）为原创作品，版权清晰，没有侵犯任何第三方的版权或其他合法权益。如因作品侵权引起任何法律纠纷，责任将由您自行承担。谢谢您的支持与理解。';

        // 作品校验
        const opusValidator = (rule, value, cb) => {
            if (!value || !value.length) return cb(new Error('作品不能为空'));
            cb();
        };
        // 标题校验器
        const titleValidator = (rule, value, cb) => {
            if (!value && value !== 0) return cb(new Error('标题不能为空'));
            cb();
        };
        // 分类校验器
        const categoryValidator = (rule, value, cb) => {
            if (!value && value !== 0) return cb(new Error('分类不能为空'));
            cb();
        };
        // 简介校验器
        const descValidator = (rule, value, cb) => {
            if (!value && value !== 0) return cb(new Error('简介不能为空'));
            cb();
        };
        // 标签校验器
        const tagsValidator = (rule, value, cb) => {
            if (!value || !value.length) return cb(new Error('标签不能为空'));
            cb();
        };

        this.awards = REWARD_TYPE;
        return {
            tagsDialogVisible: false, // 标签详情弹框是否可见

            uploadForm: {
                name: '', // * 标题
                category_id: '', // * 一级类型
                category_name: '', // * 一级类型名称
                sub_category_id: '', // * 二级类型
                sub_category_name: '', // * 二级类型名称
                descinfo: '', // * 简介
                label_id: [], // * 标签id
                opus_images: [], // * 作品
                certificate: [], // 证书
                is_award: 0, // 是否有获奖记录
                award: [], // 获奖记录
                for_sale: 0, // * 上市作品
                display: 1, // * 是否可见
                opus_cover: '', // * 封面
            },
            opus_images: [], // 作品
            certificate: [], // 证书
            selectedFirstCategoryValue: '', // 已选中的一级分类
            selectedTags: [], // 已选择的标签
            opusCover: {}, //  封面信息

            // 表单校验规则
            formRules: {
                // 作品校验
                opus_images: {
                    validator: opusValidator,
                },
                // 标题校验
                name: {
                    validator: titleValidator,
                },
                // 分类校验
                sub_category_id: {
                    validator: categoryValidator,
                },
                // 简介校验
                descinfo: {
                    validator: descValidator,
                },
                // 标签校验
                label_id: {
                    validator: tagsValidator,
                },
            },
        };
    },
    computed: {
        // 是否获奖 0 未获奖 , 1 获奖
        isAward() {
            return this.uploadForm.award.length > 0 ? 1 : 0;
        },
        // 一级分类
        firstCategoryOptions() {
            return this.category;
        },
        // 二级分类
        secondCategoryOptions() {
            return this.category[this.selectedFirstCategoryValue]?.children;
        },
        // 上市作品 0 未上市 , 1 上市
        forSale: {
            get() {
                return this.uploadForm.for_sale === 1;
            },
            set(value) {
                this.uploadForm.for_sale = value ? 1 : 0;
            },
        },
        // 仅自己可见 0 公开展示 , 1 仅自己可见
        onlySelf: {
            get() {
                return this.uploadForm.display === 0;
            },
            set(value) {
                this.uploadForm.display = value ? 0 : 1;
            },
        },
        categoryName() {
            return this.category[this.uploadForm.category_id]?.label;
        },
        subCategoryName() {
            return this.category[this.uploadForm.category_id]?.children[
                this.uploadForm.sub_category_id
            ]?.label;
        },
    },
    methods: {
        // 当确认选中的标签
        handleUpdateSelectedTags(selectedTags, done) {
            this.selectedTags = selectedTags;
            this.uploadForm.label_id = this.selectedTags.map((item) => {
                return item.id;
            });
            done();
        },
        // 处理一级分类修改
        handleFirstCategoryChange(value) {
            // 清掉二级分类
            this.uploadForm.sub_category_id = null;
            this.selectedFirstCategoryValue = value;
        },
        // 获取上传的文件列表(作品、证书)
        getUploadFiles(prop) {
            let files = this.$refs[prop].getFiles();
            if (!files) return false;
            return this.$refs[prop].getFiles().map((item) => this.addOssPrefix(item.url));
        },

        // 提交前同步form数据，把非表单绑定的value同步到form的必填项中。
        syncUploadFormData() {
            // 作品[]
            this.uploadForm.opus_images = this.getUploadFiles('uploadOpus');
            // 证书[]
            this.uploadForm.certificate = this.getUploadFiles('uploadCertificate');
            if (!this.uploadForm.opus_images || !this.uploadForm.certificate) {
                return false;
            }
            // 是否获奖
            this.uploadForm.is_award = this.isAward;
            // 一级分类名称
            this.uploadForm.category_name = this.categoryName;
            // 二级分类名称
            this.uploadForm.sub_category_name = this.subCategoryName;
            return true;
        },
        // 根据file的uid去getFiles中找对应的文件,之所以要查找,是因为file中的url是blob而非oss地址。
        findFileByUID(uid, files) {
            return files.find((item) => item.uid === uid);
        },
        // 设置封面
        handleChooseFile(chosenFile) {
            this.uploadForm.opus_cover = this.addOssPrefix(chosenFile.url);
        },
        // 给上传完图片返回的相对路径拼上oss域名使其成为完整的路径
        addOssPrefix(url) {
            return url ? `${process.env.VUE_APP_OSS_HOST}${url}` : '';
        },
    },
};
</script>
<style lang="scss" scoped>
.upload {
    .selector {
        margin-right: 1em;
    }
    // 字数上限提示半透明
    /deep/.el-input__count {
        background: #ffffff88;
    }
    .el-tag {
        margin-right: 10px;
    }
}
</style>
