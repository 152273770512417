<!-- 设计师上传作品功能中的添加标签模块 -->
<template>
    <lk-dialog
        width="45%"
        confirmButtonText="确定"
        title="选择标签"
        :visible.sync="dialogVisible"
        @confirm="handleTagDialogConfirm"
    >
        <div class="tags-wrapper">
            <!-- 标签分类 -->
            <el-tabs>
                <el-tab-pane v-for="(tag, index) in allTag" :key="index" :label="tag.name">
                    <!-- 标签内容框 -->
                    <div class="tags-content">
                        <el-button
                            size="small"
                            v-for="tag in tag.tag"
                            :key="tag.id"
                            @click="handleTagClick(tag)"
                        >
                            {{ tag.name }}
                        </el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <!-- 已选中的标签 -->
            <div class="selected-tags">
                <el-tag
                    v-for="tag in selectedTags"
                    closable
                    :key="tag.id"
                    @close="handleTagClose(tag)"
                >
                    {{ tag.name }}
                </el-tag>
            </div>
        </div>
    </lk-dialog>
</template>

<script>
import LkDialog from '@/components/LkDialog.vue';
export default {
    name: 'UploadOpusTagsDialog',
    components: {
        LkDialog,
    },
    props: {
        // 所有标签
        tags: {
            type: Object,
            default: () => {
                return {};
            },
        },
        visible: {
            type: Boolean,
            default: false,
        },
        // 父组件已选中的标签
        _selectedTags: {
            type: Array,
        },
    },
    data() {
        return {
            selectedTagType: '', // 选中的标签类型
            selectedTags: [], // 选中的标签
        };
    },
    watch: {
        dialogVisible: {
            handler(value) {
                if (value) this.selectedTags = [...this._selectedTags];
            },
            immediate: true,
        },
    },
    computed: {
        // 所有标签
        allTag() {
            return Object.keys(this.tags).map((id, index) => {
                if (index === 0) this.selectedTagType = id;
                return {
                    id: id,
                    name: this.tags[id].name,
                    tag: this.tags[id].tag,
                };
            });
        },
        // 选中类型下的所有标签
        selectedTypeTags() {
            return this.tags[this.selectedTagType]?.tag;
        },
        // 该组件显隐
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            },
        },
    },
    methods: {
        // 处理添加标签确认事件
        handleTagDialogConfirm(done) {
            this.$emit('updateSelectedTags', [...this.selectedTags], done);
        },
        // 处理标签关闭
        handleTagClose(tag) {
            let tagIdx = this.selectedTags.findIndex((item) => {
                return item === tag;
            });
            this.selectedTags.splice(tagIdx, 1);
        },
        // 点选标签
        handleTagClick(tag) {
            // 最多添加15个
            if (this.selectedTags.length >= 15) {
                this.$message({
                    type: 'warning',
                    message: '最多添加15个标签',
                    duration: 1000,
                });
                return;
            }
            // 如果在底部添加过该标签，则不重复添加
            if (this.selectedTags.includes(tag)) {
                this.$message({
                    type: 'warning',
                    message: '已添加过该标签',
                    duration: 1000,
                });
                return;
            }
            this.selectedTags.push(tag);
        },
    },
};
</script>
<style lang="scss" scoped>
.tags-wrapper {
    display: flex;
    flex-direction: column;
    div {
        margin: 0.5em 0;
    }
    // 标签内容框
    .tags-content {
        display: flex;
        flex-wrap: wrap;
        button {
            margin: 0.3em;
        }
    }
    // 选中的标签
    .selected-tags {
        .el-tag {
            margin-right: 10px;
            margin-top: 10px;
        }
    }
}
</style>
